/**
 * @see ./_press.scss
 */

.article.festival {
  > .link {
    flex-direction: column;
  }

  .article {
    &-header {
      width: 100%;
      min-width: 100%;
      padding: var(--app-outline-width);
      text-align: left;

      @include media-breakpoint-up('md') {
        padding: calc(var(--app-outline-width) / 2);
      }

      .image img {
        height: 2em;
        margin: 0;
      }
    }

    &-title {
      @include font-uppercase;

      display: inline-flex;
      flex-direction: column;
      align-items: flex-end;

      .image {
        display: inline-block;
      }

      span {
        font-size: 0.75em;
        line-height: 0.9em;
      }
    }

    &-content {
      min-height: auto;

      > div {
        flex-grow: 1;
      }
    }
  }
}
