.field {
  .field {
    &-control {
      display: flex;
      align-items: center;
      gap: var(--app-outline-width);

      label,
      .label {
        display: inline-block;
        width: 8em;
        min-width: 8em;
        font-size: 0.9524em;
        line-height: $line-height-sm;

        @include media-breakpoint-up('xs') {
          width: 10em;
          min-width: 10em;
        }
      }

      input,
      textarea {
        flex-grow: 1;
        width: 100%;
        max-width: 100%;
      }
    }

    &-description,
    &-error {
      padding-right: calc(var(--app-outline-width) / 2);
      margin-top: 0.25em;
      font-family: $font-family-italic;
      font-size: 0.7619em;
      font-style: italic;
      line-height: $line-height-sm;
      text-align: right;
    }

    &-description {
      font-weight: $font-weight-normal;
      opacity: 0.5;

      [class*='bg-'] & {
        opacity: 0.6;
      }
    }

    &-error {
      color: color('red');
      font-weight: $font-weight-bold;
      animation: appear 0.25s ease-in 0s alternate 1 backwards;
    }
  }

  & + .field {
    margin-top: 0.5em;
  }

  &.is-required {
    .form:not(.is-disabled) & {
      .field-control {
        label,
        .label {
          &::after {
            content: ' *';
            color: color('red');
            font-family: $font-family-sans;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.field.textarea-field {
  .field {
    &-control {
      textarea {
        min-height: 8em;

        @include media-breakpoint-up('xs') {
          min-height: 10em;
        }
      }
    }
  }
}

.field.search-field {
  .field-control {
    position: relative;
  }

  .clear-button {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 0.75em;
    transform: translateY(-50%);
    font-size: 0.75em;
    line-height: 0.7em;
    text-shadow: none;
    animation: appear 0.15s ease-in 0s alternate 1 backwards;
  }
}
