.headline {
  --indent-l1: 0;
  --indent-l2: 0;
  --indent-l3: 0;

  margin: 0;
  font-size: 0.85em;
  line-height: 2.1em;
  text-transform: none;

  @include media-breakpoint-up('xxs') {
    font-size: 1em;
  }

  > span {
    display: block;
    white-space: nowrap;

    &:nth-child(1) {
      margin-left: var(--indent-l1);
    }

    &:nth-child(2) {
      margin-left: var(--indent-l2);
    }

    &:nth-child(3) {
      margin-left: var(--indent-l3);
    }
  }

  [class^='sans'],
  [class^='round'] {
    font-weight: $font-weight-normal;
    font-size: 3.2381em;
  }

  [class^='sans'] {
    font-family: $font-family-headings;
  }

  [class^='round'] {
    font-family: $font-family-sans;
  }

  [class$='-upper'] {
    // font-size: 2.4em;
    font-weight: $font-weight-semibold;
    letter-spacing: -0.025em;
    text-transform: uppercase;
  }

  [class$='-semibold'] {
    font-weight: $font-weight-semibold;
    letter-spacing: -0.0125em;
  }

  [class^='sub'] {
    display: inline-block;
    font-family: $font-family-italic;
    font-size: 1.4286em;
    font-style: italic;
    font-weight: $font-weight-light;
    vertical-align: super;
    transform: translateY(-0.4em);

    &:first-child {
      margin-right: 0.1em;
    }

    &:last-child {
      margin-left: 0.1em;
      vertical-align: sub;
      transform: translateY(-0.1em);
    }
  }

  &.l-inline {
    white-space: nowrap;

    > span {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 0.5em;
      }
    }

    [class^='upper'] {
      display: inline-block;
      transform: translateY(0.025em);
    }

    [class^='sans'] {
      font-size: 3.65em;
    }

    [class^='script'] {
      display: inline-block;
      transform: translateY(-0.0125em);
    }
  }
}

p.headline {
  font-size: 0.5em;
}
