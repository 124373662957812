button:focus-visible,
[type='button']:focus-visible,
[type='reset']:focus-visible,
[type='submit']:focus-visible {
  outline: none;
}

.button {
  cursor: pointer;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  outline: none;
  color: inherit;
  font: inherit;
  line-height: $line-height-base;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  transition: color 0.25s ease-out, text-decoration 0.25s ease-out, text-underline-offset 0.25s ease-out;

  &:disabled,
  &.is-disabled {
    cursor: default;
    opacity: 0.75;
  }

  &.l-outline,
  &.l-plain {
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: 0.5em 1.5em;
    border: 1px solid currentcolor;
    border-radius: 0.35em;
    font-family: $font-family-sans;
    text-shadow: none;
    font-size: 1.1429em;
    font-weight: $font-weight-bold;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;

    &::before,
    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      border: 1px solid currentcolor;
      top: 2px;
      right: 2px;
      bottom: 2px;
      left: 2px;
      border-radius: 0.3333em;

      @media (prefers-reduced-motion: no-preference) {
        transition: clip-path 0.35s ease-out;
      }
    }

    &::before {
      clip-path: polygon(0 0, 1.125em 0, 1.125em 1.125em, 0 1.125em);
    }

    &::after {
      clip-path: polygon(
        calc(100% - 1.125em) calc(100% - 1.125em),
        100% calc(100% - 1.125em),
        100% 101%,
        calc(100% - 1.125em) 101%
      );
    }

    &:not(.is-disabled):not(:disabled):hover,
    &:not(.is-disabled):not(:disabled):focus,
    &:not(.is-disabled):not(:disabled):focus-visible,
    &:not(.is-disabled):not(:disabled):focus-within,
    .button:not(.is-disabled):not(:disabled):hover &,
    .button:not(.is-disabled):not(:disabled):focus &,
    .button:not(.is-disabled):not(:disabled):focus-visible &,
    .button:not(.is-disabled):not(:disabled):focus-within & {
      &::before {
        clip-path: polygon(calc(100% - 1.125em) 0, 100% 0, 100% 1.25em, calc(100% - 1.125em) 1.25em);
      }

      &::after {
        clip-path: polygon(0 calc(100% - 1.125em), 1.25em calc(100% - 1.125em), 1.25em 101%, 0 101%);
      }
    }
  }

  &.l-outline {
    @each $color in $color-names {
      &.c-#{$color} {
        color: color('#{$color}');
      }
    }

    &.c-charcoal {
      color: color('charcoal-light');
    }

    /** Gradient effect on rollover */
    @include media-breakpoint-up('sm') {
      @media (prefers-reduced-motion: no-preference) {
        &[class*='c-'] {
          /* stylelint-disable-next-line property-no-vendor-prefix */
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          background-size: 200%;
          background-position-x: 100%;
          transition: background-position-x 0.7s ease-out;

          &:not(:disabled):not(.is-disabled) {
            &:hover,
            &:focus,
            &:focus-visible,
            &:focus-within {
              background-position-x: -100%;
            }
          }
        }

        /** This makes the sass builder fail for production builds only :-( */
        // @each $color in $color-names {
        //   &.c-#{$color} {
        //     background-image: linear-gradient(
        //       to right,
        //       color('#{$color}') 0%,
        //       rgba(color('#{$color}', false), 0.6666) 16.6667%,
        //       rgba(color('#{$color}', false), 0.6666) 33.3333%,
        //       color('#{$color}') 50%
        //     );
        //   }
        // }

        &.c-charcoal {
          background-image: linear-gradient(
            to right,
            color('charcoal') 0%,
            rgba(color('charcoal', false), 0.6666) 16.6667%,
            rgba(color('charcoal', false), 0.6666) 33.3333%,
            color('charcoal') 50%
          );
        }

        &.c-orange {
          background-image: linear-gradient(
            to right,
            color('orange') 0%,
            rgba(color('orange', false), 0.6666) 16.6667%,
            rgba(color('orange', false), 0.6666) 33.3333%,
            color('orange') 50%
          );
        }

        &.c-green {
          background-image: linear-gradient(
            to right,
            color('green') 0%,
            rgba(color('green', false), 0.6666) 16.6667%,
            rgba(color('green', false), 0.6666) 33.3333%,
            color('green') 50%
          );
        }

        &.c-blue {
          background-image: linear-gradient(
            to right,
            color('blue') 0%,
            rgba(color('blue', false), 0.6666) 16.6667%,
            rgba(color('blue', false), 0.6666) 33.3333%,
            color('blue') 50%
          );
        }

        &.c-grey {
          background-image: linear-gradient(
            to right,
            color('grey') 0%,
            rgba(color('grey', false), 0.6666) 16.6667%,
            rgba(color('grey', false), 0.6666) 33.3333%,
            color('grey') 50%
          );
        }
      }
    }
  }

  &.l-plain {
    background-color: color('primary');
    color: color('grey');

    @each $color in $color-names {
      &.c-#{$color} {
        background-color: color('#{$color}');
        border-color: color('#{$color}');
      }
    }
  }

  &.l-arrow {
    &:disabled {
      opacity: 0.5;
      text-shadow: none;
    }

    &:not(:disabled) {
      transition: transform 0.35s ease-out;

      &.l-prev {
        &:hover,
        &:focus,
        &:focus-visible,
        &:focus-within {
          transform: translateX(-25%);
        }
      }

      &.l-next {
        &:hover,
        &:focus,
        &:focus-visible,
        &:focus-within {
          transform: translateX(25%);
        }
      }
    }
  }
}
