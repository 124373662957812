.section.flexible {
  .flexible-content {
    margin-bottom: calc(var(--app-outline-width) * 3);
    clear: both;

    &:last-child {
      /* stylelint-disable-next-line declaration-no-important -- Override .l-mb-0 class from the back-end */
      margin-bottom: 0 !important;
    }

    .hr {
      &:last-child {
        margin-bottom: 0;
      }
    }

    .flexible {
      &-title {
        &:last-child {
          margin-bottom: 0;
        }
      }

      &-text {
        .block {
          padding: calc(var(--app-outline-width) / 2) var(--app-outline-width);
          margin: 1.5em 0;
          border: 1px solid var(--current-color);
          border-radius: var(--app-outline-radius);
          font-family: $font-family-headings;
          font-feature-settings: 'kern', 'liga', 'clig', 'calt', 'lnum';
          font-size: 1.2381em;
          letter-spacing: 0.015em;
          line-height: $line-height-sm;
          text-transform: uppercase;
          clear: both;

          strong {
            font-weight: $font-weight-semibold;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        & + .link {
          margin-top: calc(var(--app-outline-width) * 2.5);

          @include media-breakpoint-up('lg') {
            margin-top: calc(var(--app-outline-width) * 1.5);
          }
        }
      }

      &-row {
        display: flex;
        flex-direction: column;
        gap: calc(var(--app-outline-width) * 3);

        @include media-breakpoint-up('md') {
          flex-direction: row;
        }

        .flexible-col {
          @include media-breakpoint-down('xs') {
            &:empty {
              display: none;
            }
          }

          &.w-25,
          &.w-33,
          &.w-50,
          &.w-67,
          &.w-75 {
            .flexible-images[data-image-count='1'] {
              img {
                width: 50vw;

                @include media-breakpoint-up('md') {
                  width: 100%;
                }
              }
            }
          }

          @include media-breakpoint-up('md') {
            &.w-25 {
              width: 25%;
            }

            &.w-33 {
              width: 33.3333%;
            }

            &.w-50 {
              width: 50%;
            }

            &.w-67 {
              width: 66.6667%;
            }

            &.w-75 {
              width: 75%;
            }
          }

          &.l-outline {
            align-self: flex-start;
            padding: var(--app-outline-width);
            border: 1px solid var(--current-color);
            border-radius: var(--app-outline-radius);

            .flexible-text {
              strong {
                text-transform: uppercase;
              }
            }
          }

          /* stylelint-disable-next-line selector-class-pattern */
          &.l-reverse_layout {
            align-self: flex-start;
            padding: var(--app-outline-width);
            border-radius: var(--app-outline-radius);

            &:not(.l-outline):not(.bg-grey) {
              .block {
                border-color: color('grey');
              }
            }
          }

          li::before {
            color: var(--current-color);
          }

          .link {
            margin-top: calc(var(--app-outline-width) * 2.5);

            @include media-breakpoint-up('lg') {
              margin-top: calc(var(--app-outline-width) * 1.5);
            }
          }

          &[class*='bg-']:not(.bg-grey) {
            li::before {
              color: color('grey');
            }
          }
        }
      }

      &-images {
        display: grid;
        gap: calc(var(--app-outline-width) / 2);

        @include media-breakpoint-up('xxxl') {
          gap: var(--app-outline-width);
        }

        .image {
          border-radius: var(--app-outline-radius);
          overflow: hidden;

          img {
            height: 100%;
            object-fit: cover;
          }
        }

        /** These columns ratios must match Flexible.jsx <Cols /> -> `imageRatios` */
        &[data-image-count='2'] {
          grid-template-columns: auto auto;
        }

        &[data-image-count='3'] {
          grid-template-columns: auto auto auto;
        }

        &[data-image-count='4'] {
          grid-template-columns: auto auto;
          grid-template-rows: auto auto;

          @include media-breakpoint-between('xs', 'md') {
            grid-template-columns: auto auto auto auto;
          }
        }
      }

      &-header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: var(--app-outline-width);
        padding-left: var(--app-outline-width);
        padding-right: var(--app-outline-width);

        @include media-breakpoint-up('sm') {
          flex-direction: row;
          align-items: flex-end;
        }

        @include media-breakpoint-up('lg') {
          padding-left: calc(var(--app-outline-width) * 2);
          padding-right: calc(var(--app-outline-width) * 2);
        }

        .flexible-title {
          flex-grow: 1;
        }

        .link {
          margin: 0 0 calc(var(--app-outline-width) / 2) var(--app-outline-width);
          white-space: nowrap;
          align-self: flex-end;
          font-family: $font-family-italic;
          font-size: 0.9534em;
          font-style: italic;
          opacity: 0.5;
        }
      }
    }

    /** ACF Layouts common properties */

    &.text,
    &.cols,
    &.gallery,
    &.accordion,
    &.filters,
    &.links {
      padding-left: var(--app-outline-width);
      padding-right: var(--app-outline-width);

      @include media-breakpoint-up('lg') {
        padding-left: calc(var(--app-outline-width) * 2);
        padding-right: calc(var(--app-outline-width) * 2);
      }
    }

    /** ACF "Header" layout */
    &.header {
      padding: calc(var(--app-outline-width) * 2.5) calc(var(--app-outline-width) * 2);
      margin-top: calc(var(--app-outline-width) * 2.5);
      border-radius: var(--app-outline-radius);

      @include media-breakpoint-up('lg') {
        margin-top: calc(var(--app-outline-width) * 1.5);
      }

      &:not(.l-cols) {
        text-align: center;
      }

      &.l-cols {
        @include media-breakpoint-up('md') {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .flexible-title {
          margin-bottom: calc(var(--app-outline-width) * 1.5);

          @include media-breakpoint-up('md') {
            margin-bottom: 0;
          }
        }
      }

      .flexible-text {
        margin-bottom: calc(var(--app-outline-width) * 1.5);

        @include media-breakpoint-up('md') {
          padding-left: calc(var(--app-outline-width) * 2);
          margin-bottom: 0;
        }

        h4 {
          font-size: $h5-font-size;
        }

        h5 {
          font-size: $h6-font-size;
        }

        h6 {
          font-size: 1em;
        }
      }

      .header-link {
        @include media-breakpoint-up('md') {
          padding-left: calc(var(--app-outline-width) * 2);
        }
      }
    }

    /** ACF "Text" layout */
    &.text {
      &.l-floating-title {
        .flexible-title {
          @include media-breakpoint-up('sm') {
            float: left;
            width: 42.5%;
            min-width: 10em;
            padding-right: calc(var(--app-outline-width) * 3);
            margin-bottom: calc(var(--app-outline-width) * 1.5);
          }
        }
      }
    }

    /** ACF "Columns" layout */
    &.cols {
      //
    }

    /** ACF "Gallery" layout */
    &.gallery {
      .flexible-images {
        grid-template-columns: repeat(3, 1fr);

        @include media-breakpoint-up('xs') {
          grid-template-columns: repeat(4, 1fr);
        }

        @include media-breakpoint-up('sm') {
          grid-template-columns: repeat(5, 1fr);
        }

        @include media-breakpoint-up('md') {
          grid-template-columns: repeat(6, 1fr);
        }
      }
    }

    /** ACF "Accordion" layout */
    &.accordion {
      .flexible-accordion {
        &:not(:last-child) {
          margin-bottom: 0.5em;
        }

        .collapsable {
          &-button {
            display: flex;
            align-items: flex-start;
            width: 100%;
            text-align: left;

            @include media-breakpoint-up('sm') {
              width: auto;
            }

            &::before {
              @include icon('\e90b', 1.25em, var(--current-color));

              display: inline-block;
              margin-right: 0.5em;
              transform: translateY(1.25em);

              @media (prefers-reduced-motion: no-preference) {
                transition: transform 0.25s ease-out;
              }
            }

            .flexible-title {
              margin: 0.25em 0;
            }
          }

          &-content {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;

            @include media-breakpoint-up('sm') {
              flex-wrap: nowrap;
            }

            .image {
              padding: 0.25em 0;
              margin: 0 auto;

              @include media-breakpoint-up('sm') {
                margin-right: var(--app-outline-width);
              }

              img {
                display: block;
                width: auto;
                min-width: 10em;
                max-height: 9em;
                border-radius: var(--app-outline-radius);
                overflow: hidden;

                @include media-breakpoint-up('sm') {
                  max-height: none;
                }

                @include media-breakpoint-up('md') {
                  max-width: 15em;
                }
              }
            }

            .flexible-text {
              flex-grow: 1;
            }
          }
        }

        &:not(.is-collapsed) {
          .collapsable-button::before {
            transform: translateY(1.25em) rotate(-180deg);
          }
        }
      }
    }

    /** ACF "Program" layout */
    &.program {
      .flexible-title {
        margin-bottom: calc(var(--app-outline-width) / 2);
      }

      &.l-outline {
        background-image: none;
        border: none;
      }
    }

    /** ACF "Filters" layout */
    &.filters {
      ul {
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: var(--app-outline-width);

        @include media-breakpoint-up('xs') {
          display: grid;
          grid-auto-flow: row;
          grid-template-columns: auto auto;
          grid-template-rows: auto auto;
          gap: calc(var(--app-outline-width) * 2);
        }

        @include media-breakpoint-up('lg') {
          gap: var(--app-outline-width);
          grid-template-columns: auto auto auto auto;
          grid-template-rows: auto;
        }

        @include media-breakpoint-up('xl') {
          gap: calc(var(--app-outline-width) * 2);
        }

        @include media-breakpoint-up('xxl') {
          gap: calc(var(--app-outline-width) * 3);
        }
      }

      li {
        @include media-breakpoint-up('lg') {
          font-size: 0.8571em;
        }

        @include media-breakpoint-up('xl') {
          font-size: 0.9524em;
        }

        @include media-breakpoint-up('xxl') {
          font-size: 1em;
        }

        &::before {
          display: none;
        }
      }

      .button {
        position: relative;
        display: block;

        span {
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          z-index: 2;
          padding: calc(var(--app-outline-width) * 2);
          margin-bottom: 0;
          color: color('white');
          text-align: left;

          @include media-breakpoint-up('lg') {
            padding: var(--app-outline-width);
          }
        }

        &:hover,
        &:focus,
        &:focus-visible,
        &:focus-within {
          .image::before {
            transform: translateY(-5%);
          }
        }
      }

      .flexible-title {
        margin-bottom: var(--app-outline-width);
      }

      .image {
        position: relative;
        width: 100%;
        height: 10em;
        overflow: hidden;
        border-radius: var(--app-outline-radius);

        @include media-breakpoint-up('sm') {
          height: 12em;
        }

        @include media-breakpoint-up('lg') {
          height: auto;
        }

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: -100%;
          left: 0;
          z-index: 1;
          background-image: linear-gradient(to top, #000c 50%, #0008 60%, #0003 87.5%, #0000 100%);
          border-radius: var(--app-outline-radius);

          @media (prefers-reduced-motion: no-preference) {
            transition: transform 0.25s ease-out;
          }
        }

        img {
          display: block;
        }
      }
    }

    /** ACF "Links" layout */
    &.links {
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: var(--app-outline-width);
        padding: 0;
      }

      li {
        &::before {
          display: none;
        }

        .button {
          @include media-breakpoint-up('sm') {
            @media (prefers-reduced-motion: no-preference) {
              background-image: linear-gradient(
                to right,
                color('grey') 0%,
                rgba(color('grey', false), 0.6666) 16.6667%,
                rgba(color('grey', false), 0.6666) 33.3333%,
                color('grey') 50%
              );
            }
          }
        }
      }
    }

    /** Options */
    &.l-mb-half {
      margin-bottom: calc(var(--app-outline-width) * 1.5);
    }

    &.l-mb-0 {
      margin-bottom: 0.75em;
    }
  }
}
