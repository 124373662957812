.festivals-map {
  @media (orientation: landscape) {
    --aspect-ratio: 1.221;

    position: absolute;
    left: 15%;
    width: 70%;
    text-align: right;

    @include media-breakpoint-up('lg') {
      @include media-breakpoint-up('md', 'height') {
        position: absolute;
        top: 10%;
        right: 0;
        left: auto;
        width: 100%;

        @media (min-aspect-ratio: 1.5) {
          top: 50%;
          transform: translateY(-50%);
        }

        @media (min-aspect-ratio: 1.85) {
          top: auto;
          width: calc((100vh - var(--app-outline-width) * 2 - var(--hero-padding) * 2) * var(--aspect-ratio));
          height: calc(100vh - var(--app-outline-width) * 2 - var(--hero-padding) * 2);
          transform: none;
        }
      }
    }
  }

  @media (orientation: portrait) {
    width: 100%;

    @include media-breakpoint-up('md') {
      position: absolute;
      left: 15%;
      width: 70%;
    }
  }

  .headline {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 1.5vw;
    line-height: 1.85em;
    text-align: left;

    @media (orientation: landscape) {
      @include media-breakpoint-up('lg') {
        @include media-breakpoint-up('md', 'height') {
          top: 30%;
          right: 0;
          bottom: auto;
          left: auto;
          transform: translateY(-50%);
          font-size: 0.5em;
        }
      }
    }

    > span:nth-child(1) {
      font-size: 0.85em;
    }

    > span:nth-child(2) {
      margin-left: 3.5em;
    }

    > span:nth-child(3) {
      margin-left: 1.5em;
    }
  }

  &-content {
    //
  }

  &-image {
    display: inline-block;
    width: 100%;
    height: 100%;
    opacity: 0.6666;
  }

  &-icons {
    padding: 0;
    margin: 0;

    > li {
      position: absolute;
      transform: translate(-50%, -50%);

      &::before {
        /* stylelint-disable-next-line declaration-no-important -- Override .template li::before */
        display: none !important;
      }
    }

    .icon {
      font-size: 5vw;

      @include media-breakpoint-up('md') {
        font-size: 3.5vw;
      }

      @media (orientation: landscape) {
        @include media-breakpoint-up('lg') {
          @include media-breakpoint-up('md', 'height') {
            font-size: 2.5vw;

            @media (min-aspect-ratio: 1.85) {
              font-size: 4.5vh;
            }
          }
        }
      }

      &::after {
        display: none;
        content: attr(aria-label);
        position: absolute;
        top: -0.5em;
        left: 50%;
        min-width: 10em;
        padding: 0.5em 1em;
        background-color: color('orange');
        border: 1px solid color('grey');
        border-radius: 5em;
        font-family: $font-family-sans;
        font-size: 1rem;
        font-weight: $font-weight-bold;
        text-align: center;
        text-transform: uppercase;
        transform: translate(-50%, -100%);

        @media (prefers-reduced-motion: no-preference) {
          animation: appear 0.25s ease-in 0s alternate 1 backwards;
        }
      }
    }

    .link {
      display: inline-block;
      text-decoration: none;

      &:hover,
      &:focus,
      &:focus-visible,
      &:focus-within {
        .icon::after {
          display: inline-block;
        }
      }
    }
  }
}
