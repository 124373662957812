$has-sticky-banner: false;

@mixin single-event-sticky-banner {
  top: var(--header-height);
  padding-bottom: 0;
  background-color: transparent;
  background-image: linear-gradient(to bottom, #fff 40%, #fff0);

  &::before,
  &::after {
    display: none;
  }
}

.template.template-single-event {
  .template {
    &-banner {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-bottom: calc(var(--app-outline-width) * 1.5);

      @include media-breakpoint-up('sm') {
        flex-direction: row;
        gap: var(--app-outline-width);
      }

      @if $has-sticky-banner {
        @include media-breakpoint-up('sm') {
          @include media-breakpoint-up('xl', 'height') {
            @include sticky-banner;
            @include single-event-sticky-banner;
          }
        }

        @include media-breakpoint-up('md') {
          @include media-breakpoint-up('lg', 'height') {
            @include sticky-banner;
            @include single-event-sticky-banner;
          }
        }
      }

      .template-header {
        &:first-child {
          order: 2;
          display: block;
          padding: var(--app-outline-width);
          flex-grow: 1;
          text-align: left;

          @include media-breakpoint-up('sm') {
            align-self: center;
          }

          .template-title {
            margin-top: var(--app-outline-width);
            margin-bottom: 0.125em;

            @include media-breakpoint-up('sm') {
              margin-top: 0;
            }
          }

          p,
          .template-title + div {
            font-weight: $font-weight-normal;
          }

          ul {
            font-size: 0.8571em;

            > li {
              font-family: $font-family-italic;
              font-style: italic;
            }
          }

          .link {
            font-weight: $font-weight-light;
          }
        }

        &:last-child {
          order: 1;
          min-width: 17.5em;
          padding-left: calc(var(--app-outline-width) * 4);
          padding-right: calc(var(--app-outline-width) * 4);

          .template-title {
            @include font-uppercase;

            font-size: 1.25em;

            img {
              margin-bottom: 0.1em;
            }
          }

          a {
            font-weight: inherit;
          }
        }
      }
    }

    &-content {
      @if $has-sticky-banner {
        @include media-breakpoint-up('sm') {
          @include media-breakpoint-up('xl', 'height') {
            padding-top: calc(var(--app-outline-width) * 3);
          }
        }

        @include media-breakpoint-up('md') {
          @include media-breakpoint-up('lg', 'height') {
            padding-top: calc(var(--app-outline-width) * 3);
          }
        }
      }

      .event {
        &-row {
          display: flex;
          flex-direction: column;
          gap: calc(var(--app-outline-width) * 1);
          width: 100%;

          @include media-breakpoint-up('sm') {
            flex-direction: row;
          }

          &.l-event-info {
            padding-left: var(--app-outline-width);
            padding-right: var(--app-outline-width);
            margin-bottom: calc(var(--app-outline-width) * 3);
            gap: calc(var(--app-outline-width) * 2);

            @include media-breakpoint-up('md') {
              gap: calc(var(--app-outline-width) * 3);
              padding-left: calc(var(--app-outline-width) * 2);
            }

            &.has-image {
              @include media-breakpoint-up('md') {
                padding-right: 0;
              }

              & > div {
                &:first-child {
                  flex-grow: 1;
                }

                &:last-child {
                  @include media-breakpoint-up('sm') {
                    width: 33.3333%;
                    min-width: 33.3333%;
                  }

                  @include media-breakpoint-up('lg') {
                    width: calc((100% - var(--app-outline-width) * 2) / 3);
                    min-width: calc((100% - var(--app-outline-width) * 2) / 3);
                  }

                  @include media-breakpoint-up('xl') {
                    width: calc((100% - var(--app-outline-width) * 4) / 3);
                    min-width: calc((100% - var(--app-outline-width) * 4) / 3);
                  }

                  @include media-breakpoint-up('xxl') {
                    width: calc((100% - var(--app-outline-width) * 2) / 3);
                    min-width: calc((100% - var(--app-outline-width) * 2) / 3);
                  }
                }
              }
            }
          }

          &.l-event-details {
            padding-bottom: 0;
            margin-bottom: calc(var(--app-outline-width) * 1.5);

            @include media-breakpoint-up('sm') {
              flex-wrap: wrap;
            }

            @include media-breakpoint-up('md') {
              flex-wrap: nowrap;
            }

            @include media-breakpoint-up('lg') {
              gap: calc(var(--app-outline-width) * 2);
            }

            @include media-breakpoint-up('xl') {
              gap: calc(var(--app-outline-width) * 3);
            }

            @include media-breakpoint-up('xxl') {
              padding-left: calc(var(--app-outline-width) * 2);
              padding-right: calc(var(--app-outline-width) * 2);
            }
          }

          &.l-event-map {
            height: 75vh;
            margin-bottom: calc(var(--app-outline-width) * 3);
          }
        }

        &-info {
          .link {
            margin-top: var(--app-outline-width);
          }
        }

        &-image {
          margin: 0 var(--app-outline-width);

          @include media-breakpoint-up('xs') {
            margin: 0 calc(var(--app-outline-width) * 3);
          }

          @include media-breakpoint-up('sm') {
            margin: 0;
          }
        }

        &-details,
        &-calendar,
        &-promoter {
          padding: var(--app-outline-width);
          border: 1px solid var(--current-color);
          border-radius: var(--app-outline-radius);
        }

        &-details,
        &-promoter {
          background-color: color('charcoal');
          background-image: linear-gradient(
            115deg,
            rgba(color('charcoal-light', false), 0.025) 0%,
            color('charcoal-dark') 43%,
            color('charcoal-dark') 57%,
            rgba(color('charcoal-light', false), 0.025) 100%
          );

          @include media-breakpoint-up('sm') {
            width: calc(50% - (var(--app-outline-width) + var(--app-outline-width-right)) / 2);
          }

          @include media-breakpoint-up('md') {
            width: 33.3333%;
          }

          h3 .icon,
          h4 .icon,
          h5 .icon,
          h6 .icon {
            display: inline-block;
            font-size: 0.75em;
            transform: translateY(-0.05em);
            color: color('grey-dark');
          }

          h3 {
            @include font-uppercase;

            margin-bottom: 0.125em;
            font-size: $h6-font-size;

            .icon {
              margin-right: 0.25em;
              font-size: 1.1rem;
            }
          }
        }

        &-details {
          .p-name {
            margin-bottom: 0;
          }

          // h3 span:last-child:not(:first-child) > span {
          //   text-decoration: underline solid rgba(color('primary', false), 0.3);
          //   text-underline-offset: 0.15em;
          // }

          .button {
            margin-bottom: 0.25em;
            transform: translateY(-0.5em);

            &:not(.is-disabled):not(:disabled) span:last-child {
              text-decoration: underline solid rgba(color('primary', false), 0.3);
              text-underline-offset: 0.15em;
              transition: text-decoration 0.25s ease-out, text-underline-offset 0.25s ease-out;
            }

            &:hover,
            &:focus,
            &:focus-visible,
            &:focus-within {
              span:last-child {
                text-decoration: underline solid rgba(color('primary', false), 0);
                text-underline-offset: 0.075em;
              }
            }
          }
        }

        &-promoter {
          @include media-breakpoint-up('sm') {
            order: 2;
          }

          @include media-breakpoint-up('md') {
            order: 3;
          }

          .image {
            margin-bottom: 0.75em;

            img {
              max-width: 10em;
              max-height: 7.5em;
              margin: 0;
            }
          }

          .link {
            font-weight: $font-weight-light;

            &[target='_blank'] {
              &::after {
                color: color('grey-dark');
              }

              &:hover,
              &:focus {
                &::after {
                  color: color('charcoal-light');
                }
              }
            }
          }

          &-links {
            .icon {
              display: inline-block;
              margin-right: 0.275rem;
              font-size: 0.75rem;
            }
          }

          &-partners {
            ul {
              font-size: 0.8571em;

              li::before {
                color: color('grey');
                font-size: 0.75rem;
                opacity: 0.7;
                transform: translate(-1.6666em, 0.25em);
              }
            }
          }

          &-reservation {
            h3 {
              margin-top: 0.5em;
            }
          }
        }

        &-calendar {
          position: relative;
          margin: 0 calc(var(--app-outline-width) * 2);

          @include media-breakpoint-up('xs') {
            margin: 0 calc(var(--app-outline-width) * 4);
          }

          @include media-breakpoint-up('sm') {
            order: 3;
            margin: 0 auto;
          }

          @include media-breakpoint-up('md') {
            order: 2;
            align-self: flex-start;
            width: 33.3333%;
          }

          &.bg-charcoal {
            background-color: color('charcoal');
            background-image: linear-gradient(
              115deg,
              rgba(color('charcoal-light', false), 0.025) 0%,
              color('charcoal-dark') 43%,
              color('charcoal-dark') 57%,
              rgba(color('charcoal-light', false), 0.025) 100%
            );
          }

          > [class*='bg-'] {
            padding: var(--app-outline-width);
            // prettier-ignore
            margin:
              calc(var(--app-outline-width) * -1) calc(var(--app-outline-width) * -1)
              calc(var(--app-outline-width) * 2) calc(var(--app-outline-width) * -1);
            border-radius: var(--app-outline-radius);
          }

          .calendar {
            @include media-breakpoint-up('md') {
              // prettier-ignore
              padding:
                calc(var(--app-outline-width) / 4) calc(var(--app-outline-width) / 2)
                calc(var(--app-outline-width) / 1.5) calc(var(--app-outline-width) / 2);
            }
          }

          .hr {
            position: absolute;
            top: 100%;
            left: 50%;
            margin-bottom: 0;
            transform: translate(-50%, calc(var(--app-outline-width) * 2));
          }
        }

        &-dates {
          display: table;
          padding-left: 0;
          font-size: 0.8571em;

          li {
            &::before {
              display: none;
            }

            &.is-selected {
              position: relative;
              z-index: 1;

              &::after {
                content: '';
                position: absolute;
                top: 0;
                right: -0.5em;
                bottom: 0;
                left: -0.5em;
                z-index: -1;
                background-color: color('grey');
                background-image: linear-gradient(
                  115deg,
                  color('grey-dark') 0%,
                  color('grey') 53%,
                  color('grey') 67%,
                  color('grey-dark') 100%
                );
                border-radius: 5em;
                opacity: 0.15;
                transform: translateY(0.075em);
              }
            }
          }

          .date-time-range {
            display: table-row;
          }

          [class^='dtr-'] {
            display: table-cell;
          }

          .dtr-times {
            padding-left: calc(var(--app-outline-width) / 2);

            &::before {
              color: var(--current-color);
            }
          }
        }
      }
    }

    &-footer {
      .hr {
        @include media-breakpoint-up('md') {
          margin-top: calc(-6.1412vw + var(--app-outline-width) * 2);
        }
      }
    }
  }

  @each $color in $color-names-no-white {
    &.c-#{$color} {
      .template-content {
        .event {
          &-dates li.is-selected::after {
            background-image: linear-gradient(
              115deg,
              color('#{$color}-light') 0%,
              color('#{$color}') 43%,
              color('#{$color}') 57%,
              color('#{$color}-light') 100%
            );
          }

          &-details,
          &-promoter {
            h3,
            h4,
            h5,
            h6 {
              .icon {
                color: color('#{$color}-dark');
              }
            }
          }
        }
      }
    }

    &.c-charcoal {
      .template-content {
        .event {
          &-dates li.is-selected::after {
            background-image: linear-gradient(
              115deg,
              color('grey-dark') 0%,
              color('grey') 53%,
              color('grey') 67%,
              color('grey-dark') 100%
            );
          }

          &-details,
          &-promoter {
            h3,
            h4,
            h5,
            h6 {
              .icon {
                color: color('grey-dark');
              }
            }
          }
        }
      }
    }
  }
}
