.count-up {
  width: 100%;
  padding: calc(var(--app-outline-width) * 5) 0;
  text-align: center;

  @include media-breakpoint-up('xs') {
    padding: calc(var(--app-outline-width) * 4) 0;
  }

  @include media-breakpoint-up('sm') {
    padding: calc(var(--app-outline-width) * 3) 0;
  }

  @include media-breakpoint-up('md') {
    width: 50%;
    padding: 0;
    margin-left: calc(50% + var(--app-outline-width) * 2);
    text-align: left;
  }

  .section.l-reverse.has-image + & {
    @include media-breakpoint-up('md') {
      margin-top: calc(var(--app-outline-width) * -7.25);
    }

    @include media-breakpoint-up('lg') {
      margin-top: calc(var(--app-outline-width) * -6.5);
    }

    @include media-breakpoint-up('xl') {
      margin-top: calc(var(--app-outline-width) * -6);
    }
  }

  p {
    margin: 0;

    > span {
      display: inline-block;

      &:nth-child(1) {
        vertical-align: top;
        font-family: $font-family-italic;
        font-size: 1.4286em;
        font-style: italic;
        font-weight: $font-weight-light;
        line-height: 1em;
        text-align: center;
      }

      &:nth-child(2) {
        width: 7.5rem;
        height: 7.5rem;
        margin-right: 0.675rem;
        font-family: $font-family-sans;
        font-size: 5.5em;
        font-weight: $font-weight-medium;
        line-height: 6rem;
        text-align: center;
        border: 1px solid var(--current-color);
        border-radius: 100%;
      }

      &:nth-child(3) {
        padding-bottom: 0.75em;
        vertical-align: bottom;
        text-align: right;

        > span {
          line-height: 1em;

          &:nth-child(1) {
            font-family: $font-family-headings;
            font-size: 3.0476em;
            font-weight: $font-weight-semibold;
          }

          &:nth-child(2) {
            padding-right: 0.1em;
            font-family: $font-family-italic;
            font-size: 1.4286em;
            font-style: italic;
            font-weight: $font-weight-light;
          }
        }
      }

      > span {
        display: block;
      }
    }
  }
}
