.hr {
  width: 15em;
  height: 3.9033em;
  margin: 0 auto calc(var(--app-outline-width) * 3) auto;
  border: none;
  clear: both;
  background-color: var(--current-color);
  mask: url('../../images/heart-separator.svg');
  mask-size: contain;

  @include media-breakpoint-up('md') {
    width: 30vw;
    height: 7.8066vw;
  }

  &.c-charcoal {
    background-color: color('charcoal-light');
  }

  &.l-mb-half {
    margin-bottom: calc(var(--app-outline-width) * 1.5);
  }
}
