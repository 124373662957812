.field.date-field,
.field.time-field {
  .input {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.3333em;
    padding-bottom: 0.1667em;
    font-family: $font-family-sans;
    font-size: 0.9524rem;
    text-align: center;

    .icon {
      margin-left: 0.3333em;
      font-size: 0.6666em;
      color: color('red');
    }
  }

  .date-segment {
    display: inline-block;
    position: relative;
    border-radius: 0.5em;
    overflow: hidden;
    font-variant-numeric: tabular-nums;
    text-align: end;
    transition: color 0.15s ease-out;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      background-color: transparent;
      opacity: 0.3;
      transition: opacity 0.15s ease-out, background-color 0.15s ease-out;
    }

    &:focus {
      color: color('white');
      outline: none;

      &::after {
        background-color: var(--current-color);
      }
    }

    &[aria-hidden] {
      font-weight: $font-weight-light;
    }

    /* stylelint-disable-next-line selector-no-vendor-prefix */
    &::-moz-selection {
      background-color: color('charcoal-light');
    }

    &::selection {
      background-color: color('charcoal-light');
    }
  }

  [class*='bg-'] & {
    .date-segment {
      &:focus {
        &::after {
          background-color: color('white');
        }
      }
    }
  }
}

.field.date-range-field,
.field.time-range-field {
  .field-group {
    display: flex;
  }

  .date-field,
  .time-field {
    .label {
      @include visually-hidden;
    }
  }

  .icon[class*='chevron-down'] {
    align-self: center;
    display: inline-block;
    transform: rotate(-90deg);
  }
}
