/**
 * Whether to use css or sass variables
 * Responsive variables only works with $css-vars: true
 */
$css-vars: true !default;

 /**
  * Colors
  */
$colors: (
  --primary:          #efefef,
  --secondary:        #f39252,
  --orange:           #f39252,
  --orange-dark:      #b25118,
  --orange-light:     #f6a97a,
  --orange-alt:       #f3b852,
  --green:            #72b741,
  --green-dark:       #3a7a0c,
  --green-light:      #98d76b,
  --green-alt:        #328c6a,
  --blue:             #3e6aac,
  --blue-dark:        #123e7f,
  --blue-light:       #5f87c3,
  --blue-alt:         #5746b4,
  --charcoal:         #3a3a3a,
  --charcoal-dark:    #2d2d2d,
  --charcoal-light:   #535353,
  --grey:             #efefef,
  --grey-dark:        #dedede,
  --grey-light:       #f1f1f1,
  --black:            #000,
  --white:            #fff,
  --red:              #f13d3d,
  --red-dark:         #f51212,
  --red-light:        #f15c5c,
);
@if $css-vars {
  :root {
    @each $color, $value in $colors {
      #{$color}: #{$value};
    }
  }
}

$color-names: charcoal  orange green blue grey white;
$color-names-no-white: charcoal  orange green blue grey;
/**
 * Breakpoints
 */
$breakpoints: (
  width-xxs:    460px,
  width-xs:     576px,
  width-sm:     768px,
  width-md:     992px,
  width-lg:    1200px,
  width-xl:    1560px,
  width-xxl:   1900px,
  width-xxxl:  2260px,
  width-huge:  2520px,
  height-xxs:   320px,
  height-xs:    420px,
  height-sm:    540px,
  height-md:    660px,
  height-lg:    800px,
  height-xl:    940px,
  height-xxl:  1080px,
  height-xxxl: 1220px,
  height-huge: 1360px
);

/**
 * Fonts
 */
$font-family-sans: rooney-sans, 'Helvetica Neue', Calibri, Helvetica, Arial, sans-serif;
$font-family-headings: din-2014-narrow, 'Helvetica Neue', Calibri, Helvetica, Arial, sans-serif;
$font-family-italic: din-2014, 'Helvetica Neue', Calibri, Helvetica, Arial, sans-serif;
$font-size-base: 1.0625rem; // Assumes the browser default, typically `16px`
$font-size-xs:  ($font-size-base / 1.5);
$font-size-sm:  ($font-size-base / 1.25);
$font-size-lg:  ($font-size-base * 1.25);
$font-size-xl:  ($font-size-base * 1.5);
$font-weight-thin:      100; // Available for
$font-weight-xlight:    200; // Available for
$font-weight-light:     300; // Available for sans, italic
$font-weight-normal:    400; // Available for sans, italic, headings
$font-weight-medium:    500; // Available for sans
$font-weight-semibold:  600; // Available for uppercase, headings
$font-weight-bold:      700; // Available for sans, italic
$font-weight-extrabold: 800; // Available for
$font-weight-black:     900; // Available for
$font-weight-ultra:    1000; // Available for
$line-height-base: 1.2924;
$line-height-sm:   1.1087;
$line-height-lg:   1.476;

/**
 * Headings
 */
$h1-font-size: 2em;
$h2-font-size: 2.5714em;
$h3-font-size: 2.5714em;
$h4-font-size: 2.1905em;
$h5-font-size: 1.8095em;
$h6-font-size: 1.4286em;
$h7-font-size: 1.1905em;

/**
 * Custom variables
 */
$variables: (
  /** Scrollbar width, set in javascript -- The unit must be set in order to be usable in calc() */
  --scrollbar-width: 0px,
  /** App outline properties */
  --app-outline-width: 1.5vw,
  --app-outline-radius: 1vw,
  --app-outline-color: color('charcoal-dark'),
  /** Header properties */
  --header-height: 2.5em,
  /** Container properties */
  --container-padding-h: 2vw,
  --container-padding-v: 2em,
  --container-max-width: 2880px,
  /** Font properties */
  --font-size-base: $font-size-base,
);
@if $css-vars {
  :root {
    @each $variable, $value in $variables {
      #{$variable}: #{$value};
    }
  }
}

/**
 * Responsive variables
 */
@if $css-vars {
  @include media-breakpoint-up('sm') {
    $variables: (
      --header-height: 3.8095em,
    );
    :root {
      @each $variable, $value in $variables {
        #{$variable}: #{$value};
      }
    }
  }

  @media (min-aspect-ratio: 2.25) {
    $variables: (
      --app-outline-width: calc(min(1.75em, 1.5vw)),
    );
    :root {
      @each $variable, $value in $variables {
        #{$variable}: #{$value};
      }
    }
  }
}
