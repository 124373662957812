.hero {
  --hero-padding: calc(var(--app-outline-width) * 3);
  --hero-fading-duration: 0.333s;

  position: relative;
  z-index: 1;
  padding: var(--hero-padding);
  border-radius: var(--app-outline-radius);

  @include media-breakpoint-up('sm') {
    @include media-breakpoint-up('md', 'height') {
      min-height: calc(100vh - var(--app-outline-width) * 2);

      @media (min-aspect-ratio: 2.25) {
        --hero-padding: calc(var(--app-outline-width) * 1.5);
      }

      @media (min-aspect-ratio: 3) {
        --hero-padding: calc(var(--app-outline-width) * 1);
      }
    }
  }

  .hero {
    &-background,
    &-background-current,
    &-background-next,
    &-background-hover {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
    }

    &-background {
      border-radius: var(--app-outline-radius);
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        // prettier-ignore
        background-image: linear-gradient(
            -15deg,
            rgba(0, 0, 0, 0.95) 5%,
            rgba(0, 0, 0, 0.1) 57%,
            rgba(0, 0, 0, 0.1) 71%,
            rgba(0, 0, 0, 0.825) 95%
          ),
          linear-gradient(
            37.5deg,
            rgba(0, 0, 0, 0.95) -5%,
            rgba(0, 0, 0, 0.05) 60%,
            rgba(0, 0, 0, 0) 70%
          );

        @media (orientation: landscape) {
          // prettier-ignore
          background-image: linear-gradient(
              -15deg,
              rgba(0, 0, 0, 0.825) -5%,
              rgba(0, 0, 0, 0.05) 43%,
              rgba(0, 0, 0, 0.05) 57%,
              rgba(0, 0, 0, 0.825) 95%
            ),
            linear-gradient(
              7.5deg,
              rgba(0, 0, 0, 0.825) 5%,
              rgba(0, 0, 0, 0.05) 43%,
              rgba(0, 0, 0, 0) 57%
            );
        }
      }

      &-current,
      &-next,
      &-hover {
        background-attachment: fixed;
        background-image: var(--background-image);
        background-size: cover;
        background-position: center;
      }

      &-current {
        z-index: -2;

        @media (prefers-reduced-motion: no-preference) {
          &.is-fading {
            transition: opacity var(--hero-fading-duration) ease-out;
          }
        }
      }

      &-next {
        z-index: -3;
      }

      &-hover {
        z-index: -1;
        opacity: 0;

        @media (prefers-reduced-motion: no-preference) {
          transition: opacity var(--hero-fading-duration) ease-out;
        }
      }
    }

    &-title {
      //
    }

    &-logo {
      display: block;
      width: auto;
      height: 2.4rem;
      margin-bottom: 2rem;

      @include media-breakpoint-up('lg') {
        height: 3.619rem;
      }
    }

    &-program-link {
      //
    }

    &-content {
      margin-top: 2rem;
    }

    &-festivals {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 var(--hero-padding) var(--hero-padding) 0;

      li {
        padding: 1rem 0 0 var(--hero-padding);
        // mix-blend-mode: difference;

        &::before {
          display: none;
        }

        .link {
          text-decoration: none;
          opacity: 0.6;
          transition: opacity var(--hero-fading-duration) ease-out;

          &:hover,
          &:focus,
          &:focus-visible,
          &:focus-within,
          &.is-active {
            opacity: 1;
          }
        }

        .image img {
          height: 2.4rem;
          filter: brightness(0) invert(1);

          @include media-breakpoint-up('lg') {
            height: 3.619rem;
          }
        }

        .year {
          @include font-uppercase;

          display: block;
          font-family: $font-family-italic;
          font-size: 1em;
          font-style: italic;
          line-height: 0.9em;
          text-align: right;
        }
      }
    }

    &-links {
      @include media-breakpoint-up('sm') {
        display: flex;
        gap: var(--hero-padding);
        width: 50%;
      }

      .link {
        display: inline-block;
        margin-top: var(--hero-padding);
        text-decoration: none;

        @include media-breakpoint-up('sm') {
          margin-top: 0;
        }
      }

      h3 {
        margin-bottom: 0;

        @include media-breakpoint-up('xs') {
          white-space: nowrap;
        }
      }

      p {
        font-weight: $font-weight-light;
        text-decoration: underline solid rgba(color('white', false), 0.2);
        text-underline-offset: 0.15em;
        transition: color 0.25s ease-out, text-decoration 0.25s ease-out, text-underline-offset 0.25s ease-out;
      }

      &:hover,
      &:focus,
      &:focus-visible,
      &:focus-within {
        p {
          text-decoration: underline solid rgba(color('white', false), 0);
          text-underline-offset: 0.075em;
        }
      }
    }
  }

  /** Front page hero */
  &.l-app {
    .hero {
      &-content {
        @include media-breakpoint-up('sm') {
          @include media-breakpoint-up('md', 'height') {
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            margin-top: 0;
          }
        }
      }
    }

    /** Scroll down arrow */
    @keyframes hero-scroll-arrow {
      0% {
        transform: translate3d(-50%, -5%, 0);
      }

      100% {
        transform: translate3d(-50%, 0, 0);
      }
    }

    &::before,
    &::after {
      @include icon('\e908', 1.5em, color('white'));

      display: inline-block;
      position: absolute;
      right: 0;
      bottom: calc(var(--app-outline-width) - 0.2em);
      z-index: 101;
      transform: translateX(-50%);
      transition: opacity 0.25s ease-out;

      @media (prefers-reduced-motion: no-preference) {
        animation: hero-scroll-arrow 2.25s ease-in infinite alternate backwards;
      }

      @include media-breakpoint-up('lg') {
        @include media-breakpoint-up('md', 'height') {
          right: auto;
          left: 50%;
          bottom: -0.2em;
          color: color('primary');
        }
      }
    }

    &::before {
      bottom: var(--app-outline-width);

      @include media-breakpoint-up('lg') {
        @include media-breakpoint-up('md', 'height') {
          bottom: 0;
        }
      }

      @media (prefers-reduced-motion: no-preference) {
        animation: hero-scroll-arrow 2.25s ease-in infinite alternate backwards 1.125s;
      }
    }
  }

  /** Festivals menu hero */
  &.l-festivals {
    min-height: 100%;

    .hero {
      &-background {
        &::before {
          // prettier-ignore
          background-image: linear-gradient(
              -15deg,
              rgba(0, 0, 0, 0.75) 0%,
              rgba(0, 0, 0, 0.15) 43%,
              rgba(0, 0, 0, 0.15) 57%,
              rgba(0, 0, 0, 0.75) 100%
            ),
            linear-gradient(
              7.5deg,
              rgba(0, 0, 0, 0.75) 0%,
              rgba(0, 0, 0, 0.15) 43%,
              rgba(0, 0, 0, 0.15) 57%
            );
        }
      }

      &-content {
        //
      }

      &-links {
        @include media-breakpoint-up('sm') {
          @include media-breakpoint-up('lg', 'height') {
            position: absolute;
            bottom: var(--hero-padding);
            right: var(--hero-padding);
            left: var(--hero-padding);
          }
        }
      }

      &-festivals {
        li {
          .image img {
            height: 3rem;

            @include media-breakpoint-up('lg') {
              height: 3.619rem;
            }
          }
        }
      }
    }
  }
}

/** Scroll down arrow */
.header.is-scrolled + .template .hero.l-app::before,
.header.is-scrolled + .template .hero.l-app::after {
  opacity: 0;
}
