.template.template-program {
  .template {
    &-banner {
      padding-bottom: var(--app-outline-width);

      @include media-breakpoint-up('sm') {
        display: flex;
        flex-wrap: wrap;
        gap: var(--app-outline-width);
        width: 100%;

        @include media-breakpoint-up('xl', 'height') {
          @include sticky-banner;
        }
      }

      @include media-breakpoint-up('md') {
        flex-wrap: nowrap;

        @include media-breakpoint-up('lg', 'height') {
          @include sticky-banner;
        }
      }

      .template-header {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        padding-bottom: var(--app-outline-width);

        @include media-breakpoint-up('sm') {
          height: calc(var(--app-outline-width) * 2 + 8.5em);
          margin-right: calc(var(--app-outline-width) * 3 + var(--app-outline-width-right) + 2em);
        }

        @include media-breakpoint-up('md') {
          width: auto;
          height: auto;
          margin-right: 0;
          padding-bottom: calc(var(--app-outline-width) / 2);
        }

        h2.headline {
          margin-top: -0.5em;
        }

        .festivals-filter {
          align-self: flex-end;
          margin-top: 1.25em;

          @include media-breakpoint-up('md') {
            margin: 3em -1.75em 0 0;
          }

          .headline.l-inline {
            & > span:nth-child(1) {
              display: block;

              @include media-breakpoint-up('xxs') {
                display: inline;
              }
            }
          }
        }
      }

      .filters {
        flex-grow: 1;
        padding: calc(var(--app-outline-width) / 2) 0;

        @include media-breakpoint-up('md') {
          position: relative;
          width: auto;
        }

        @include media-breakpoint-down('sm') {
          position: fixed;
          right: var(--app-outline-width-right);
          bottom: var(--header-height);
          left: var(--app-outline-width);
          z-index: 5;
          padding-bottom: var(--app-outline-width);
          background-color: transparent;

          &:not(.has-search) {
            background-color: var(--app-outline-color);
          }

          @media (prefers-reduced-motion: no-preference) {
            transition: transform 0.35s ease-out, background-color 0.35s ease-out 0.35s;

            li {
              transition: opacity 0.35s ease-out;
            }

            &::before,
            &::after {
              transition: opacity 0.35s ease-out 0.35s;
            }
          }

          /** Bottom left & right rounded corners above the filters */
          @include bottom-corners(false, auto);

          &::before,
          &::after {
            position: absolute;
            top: 0;
            z-index: 1;
            transform: translateY(-100%);
          }

          &::before {
            left: 0;
          }

          &::after {
            right: 0;
          }

          &.is-hidden {
            transform: translateY(100%);

            > .filters-list > li {
              opacity: 0;
            }
          }

          &.has-search {
            transition: none;
            transform: none;

            > .filters-list > li:not(.search-filter-wrapper),
            &::before,
            &::after {
              opacity: 0;
              transition: none;
            }
          }
        }

        &-list {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-content: space-between;
          height: 100%;
          padding: 0;

          li {
            display: flex;
            align-items: center;
            height: 100%;
            padding-left: calc(var(--app-outline-width) / 2);
            border-bottom: 1px solid var(--current-color);

            &:last-child {
              @include media-breakpoint-up('sm') {
                border-bottom: none;
              }
            }

            &::before {
              display: none;
            }
          }
        }

        .search-filter-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          /* stylelint-disable-next-line declaration-no-important -- Override .template.template-program .template-banner .filters-list li */
          border: none !important;
        }

        .filter {
          display: flex;
          width: 100%;
          height: 100%;

          @include media-breakpoint-between('sm', 'md') {
            min-height: 2.25em;
          }

          .menu {
            .close-button {
              font-size: 1em;

              .icon-xmark::before {
                content: '\1f7ab';
              }
            }
          }

          &.program-filter {
            position: relative;

            @include media-breakpoint-up('md') {
              position: static;
            }

            .menu {
              position: fixed;
              top: calc(var(--app-outline-width) * 2);
              right: calc(var(--app-outline-width-right) + var(--app-outline-width));
              bottom: calc(var(--app-outline-width) * 1.5 + var(--header-height));
              left: calc(var(--app-outline-width) * 2);
              z-index: 20;
              margin-left: 0;
              outline: calc(var(--app-outline-width) * 2) solid var(--app-outline-color);

              @include media-breakpoint-up('sm', 'height') {
                @include media-breakpoint-up('xs') {
                  position: absolute;
                  top: 100%;
                  right: calc(var(--app-outline-width) * 1 - 1px);
                  bottom: auto;
                  left: calc(var(--app-outline-width) * 0.5 - 1px);
                  min-height: 9.5rem;
                  outline-width: var(--app-outline-width);
                  transform: translateY(calc(-100% - var(--app-outline-width)));
                }

                @include media-breakpoint-up('sm') {
                  top: var(--app-outline-width);
                  transform: none;
                }

                @include media-breakpoint-up('md') {
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  min-height: auto;
                  outline: none;
                }
              }
            }
          }

          &.search-filter {
            .filter-overlay {
              .field {
                &.search-field {
                  margin: 1em 0;

                  label {
                    @include visually-hidden;
                  }
                }
              }
            }
          }

          &.date-filter {
            position: relative;

            .menu {
              top: 100%;
              right: calc(var(--app-outline-width-right));
              left: calc(var(--app-outline-width) / 2);
              margin-left: 0;
              outline: calc(var(--app-outline-width) * 2) solid var(--app-outline-color);
              transform: translateY(-100%);

              @include media-breakpoint-up('xs') {
                left: calc(var(--app-outline-width) * 0.5 - 1px);
                right: auto;
                outline-width: var(--app-outline-width);
                transform: translateY(calc(-100% - var(--app-outline-width)));
              }

              @include media-breakpoint-up('sm') {
                top: var(--app-outline-width);
                transform: none;
              }

              @include media-breakpoint-up('md') {
                margin-left: calc(var(--app-outline-width) / -1);
                // ? Should we remove outline ?
                // top: 0;
                // outline: none;
              }

              @include media-breakpoint-up('xxl') {
                // ? Or make it half thicker ?
                // top: calc(var(--app-outline-width) / 2);
                // outline-width: calc(var(--app-outline-width) / 2);
              }
            }
          }

          &-button {
            white-space: nowrap;
            margin: 0.25em 0;

            @include media-breakpoint-up('sm') {
              margin: 0;
            }
          }

          &-overlay {
            display: flex;
            flex-direction: column;
            height: 100%;

            legend {
              margin-bottom: 0.25em;
              font-size: 1.2391em;
              font-weight: $font-weight-medium;
              text-transform: uppercase;
            }

            .checkbox {
              &-group {
                display: flex;
                flex-grow: 1;
                flex-wrap: wrap;
                align-content: flex-start;
                align-items: flex-start;
                gap: 0.5em;
                overflow: scroll;

                @include scrollbar(color('white'));
              }

              &-label {
                position: relative;
                padding: 0.2em 0.5em 0.1em 0.5em;
                border: 1px solid currentcolor;
                border-radius: 5em;
                font-size: 0.8571em;
                font-weight: $font-weight-normal;
                overflow: hidden;

                &::before {
                  content: '';
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  z-index: -1;
                  background-color: currentcolor;
                  opacity: 0;
                  transition: opacity 0.35s ease;
                }

                &:hover,
                &:focus,
                &:focus-visible,
                &:focus-within {
                  &::before {
                    opacity: 0.1;
                  }
                }
              }
            }

            &-filters {
              overflow: scroll;

              @include scrollbar(color('white'));

              p {
                margin-bottom: 0;
                font-size: 0.7619em;
              }

              .filters-list {
                height: auto;

                li {
                  padding-left: 0;
                }

                .filter {
                  height: 2em;

                  &-title {
                    font-size: 0.8571em;
                    font-weight: $font-weight-normal;
                    text-transform: uppercase;
                    padding-right: 0.25em;

                    @include media-breakpoint-up('md') {
                      padding-right: 0;
                      padding-bottom: 0.25em;
                    }
                  }

                  &-active-filters {
                    height: 100%;

                    > div {
                      right: 0;
                    }

                    .button {
                      font-size: 0.7143em;
                    }
                  }
                }
              }
            }
          }

          &-active-filters {
            flex-grow: 1;
            width: 100%;
            max-width: 100%;
            position: relative;

            > div {
              display: flex;
              align-items: center;
              gap: 0.5em;
              position: absolute;
              bottom: 0;
              left: calc(var(--app-outline-width) / 2);
              right: 0;
              overflow: scroll;

              @include scrollbar(var(--current-color));

              @include media-breakpoint-up('md') {
                right: calc(var(--app-outline-width) * -0.25 + 2px);
                padding-bottom: 0.1em;
              }

              @include media-breakpoint-up('lg') {
                padding-bottom: 0.15em;
              }

              @include media-breakpoint-up('xl') {
                padding-bottom: 0.25vw;
              }

              @media only screen and (hover: none) and (pointer: coarse) {
                padding-bottom: 0.4em;

                @include media-breakpoint-up('md') {
                  padding-bottom: 0.5em;
                }

                @include media-breakpoint-up('xl') {
                  padding-bottom: 0.525em;
                }
              }
            }
          }
        }

        &.is-hidden {
          /* stylelint-disable-next-line declaration-no-important -- Override Facebook container plugin fucking styles */
          display: block !important;
        }
      }

      [class*='bg-'] .filter .filter-active-filters > div {
        @include scrollbar(color('white'));
      }

      .actions {
        position: fixed;
        right: var(--app-outline-width-right);
        bottom: var(--app-outline-width);
        left: var(--app-outline-width);
        z-index: 10;
        height: var(--header-height);
        padding: var(--app-outline-width) 0 0 0;
        background-color: var(--app-outline-color);

        @include media-breakpoint-up('sm') {
          // order: 2;
          position: fixed;
          top: var(--app-outline-width);
          bottom: auto;
          left: auto;
          height: auto;
          border: 1px solid var(--current-color);
          border-radius: var(--app-outline-radius);
          padding: var(--app-outline-width);
        }

        @include media-breakpoint-up('md') {
          position: static;
        }

        /** Bottom left & right rounded corners */
        @include bottom-corners(true, calc(var(--app-outline-width) + var(--header-height)));

        /** Hide the corners for desktop */
        @include media-breakpoint-up('sm') {
          &::before,
          &::after {
            display: none;
          }
        }

        ul {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          height: 100%;
          padding: 0;
          text-align: center;

          @include media-breakpoint-up('sm') {
            flex-direction: column;
            justify-content: space-between;
            align-content: space-between;
          }

          li {
            height: 2.5em;
            font-size: 0.75em;

            @include media-breakpoint-up('sm') {
              font-size: 1em;

              &:not(:last-child) {
                margin-bottom: 0.5em;
              }

              &:nth-last-child(2) {
                margin-bottom: 0;
              }

              &:last-child {
                display: none;
              }
            }
          }

          li::before {
            display: none;
          }
        }

        .link {
          text-decoration: none;
        }

        .icon,
        .button::after {
          transition: opacity 0.25s ease-out, transform 0.25s ease-out;
        }

        .button {
          display: inline-block;
          position: relative;

          @include media-breakpoint-up('sm') {
            &::after {
              content: attr(aria-label);
              display: block;
              opacity: 0.5;
              font-size: 0.5714em;
              line-height: 0.5em;
            }

            &.map-button::after {
              margin-top: -0.2em;
            }

            &.filters-button::after {
              // margin-top: -0.25em;
            }
          }

          &:hover,
          &:focus,
          &:focus-visible,
          &:focus-within,
          &.is-active {
            .icon {
              color: var(--current-color);
            }

            @include media-breakpoint-up('md') {
              &::after {
                opacity: 1;
              }
            }
          }

          &::before {
            display: inline-block;
            content: '';
            position: absolute;
            top: -0.25em;
            right: -0.25em;
            width: 0.5em;
            height: 0.5em;
            border-radius: 100%;
            background-color: transparent;
            transition: background-color 0.25s ease;
          }

          &.is-selected {
            color: var(--current-color);

            &::before {
              background-color: color('charcoal');
            }
          }
        }

        .icon {
          display: inline-block;
          font-size: 1.65em;

          &.icon-map {
            font-size: 2em;

            @include media-breakpoint-up('md') {
              line-height: 0.825em;
            }
          }

          &.icon-filters {
            font-size: 1.8em;

            @include media-breakpoint-up('md') {
              line-height: 0.825em;
            }
          }
        }
      }
    }

    &-content {
      padding: 0 0 var(--header-height) 0;

      @include media-breakpoint-up('sm') {
        padding-bottom: 0;
      }

      @media (prefers-reduced-motion: no-preference) {
        transition: padding-bottom 0.35s ease-out;
      }

      .no-result {
        margin: calc(var(--app-outline-width) * 2) 0 calc(var(--app-outline-width) * 3) 0;
      }
    }
  }

  &.has-filters {
    .template-content {
      padding-bottom: calc(var(--header-height) + 9.5rem);

      @include media-breakpoint-up('sm') {
        padding-bottom: 0;
      }
    }

    & + .layout.footer:not(.visually-hidden) {
      @include media-breakpoint-down('sm') {
        margin-bottom: calc(var(--header-height) + 9.5rem);
      }
    }
  }

  &.has-map {
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--app-outline-width) * 2);

    .template-content {
      flex-grow: 1;
    }

    .section.program {
      height: 100%;
    }

    & + .footer {
      @include visually-hidden;
    }
  }

  &.has-no-result {
    .template-content {
      padding-bottom: 0;
    }

    .layout.header:not(.visually-hidden) + & + .layout.footer:not(.visually-hidden) {
      @include media-breakpoint-down('sm') {
        margin-bottom: calc(var(--header-height) * 2 - var(--app-outline-width));
      }
    }
  }

  /** The header is shown when the user lands directly to the program page */
  .layout.header:not(.visually-hidden) + & {
    .template-banner {
      /** These queries must match the base .template-banner queries where the sticky-banner is displayed */
      @include media-breakpoint-up('sm') {
        @include media-breakpoint-up('xl', 'height') {
          top: var(--header-height);
        }
      }

      @include media-breakpoint-up('md') {
        @include media-breakpoint-up('lg', 'height') {
          top: var(--header-height);
        }
      }

      .filters {
        @include media-breakpoint-down('sm') {
          bottom: calc(var(--header-height) * 2);
        }
      }

      .actions {
        bottom: var(--header-height);
        border-bottom: 1px solid color('charcoal-light');

        @include media-breakpoint-up('sm') {
          top: var(--header-height);
          bottom: auto;
          border-color: var(--current-color);
        }
      }
    }

    .template-content {
      padding-bottom: 0;
    }

    &.has-filters {
      & + .layout.footer:not(.visually-hidden) {
        margin-bottom: 0;

        @include media-breakpoint-down('sm') {
          margin-bottom: calc(var(--header-height) * 2 - var(--app-outline-width) + 9.5rem);
        }
      }
    }
  }

  /** The footer is shown when the user lands directly to the program page or when there is no events to display */
  & + .layout.footer:not(.visually-hidden) {
    margin-top: var(--app-outline-width);

    @media (prefers-reduced-motion: no-preference) {
      transition: margin-bottom 0.35s ease-out;
    }
  }

  .layout.header:not(.visually-hidden) ~ .layout.footer:not(.visually-hidden) {
    @include media-breakpoint-down('sm') {
      margin-bottom: calc(var(--header-height) * 2 - var(--app-outline-width));
    }
  }
}
