.layout.header {
  $mobile-breakpoint: 'sm';

  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  height: var(--header-height);
  padding: calc(var(--header-height) / 4) var(--app-outline-width);
  background-color: var(--app-outline-color);

  @include media-breakpoint-up($mobile-breakpoint) {
    align-items: flex-end;
    top: 0;
    bottom: auto;
  }

  @media (prefers-reduced-motion: no-preference) {
    transition: transform 0.25s ease-in;
  }

  /** Bottom left & right rounded corners */
  @include bottom-corners(true, var(--header-height));

  /** Move the corners to top for desktop */
  @include media-breakpoint-up($mobile-breakpoint) {
    &::before,
    &::after {
      top: var(--header-height);
      bottom: auto;
    }

    &::before {
      /*! autoprefixer: off */

      mask: radial-gradient(var(--app-outline-radius) at 100% 100%, #0000 100%, #000);
      /* stylelint-disable-next-line property-no-vendor-prefix -- Override autoprefixer's wrong rules */
      -webkit-mask: radial-gradient(var(--app-outline-radius) at 100% 100%, #0000 100%, #000);
    }

    &::after {
      /*! autoprefixer: off */

      mask: radial-gradient(var(--app-outline-radius) at 0 100%, #0000 100%, #000);
      /* stylelint-disable-next-line property-no-vendor-prefix -- Override autoprefixer's wrong rules */
      -webkit-mask: radial-gradient(var(--app-outline-radius) at 0 100%, #0000 100%, #000);
    }
  }

  &.is-front-page:not(.is-scrolled) {
    transform: translateY(100%);

    @include media-breakpoint-up($mobile-breakpoint) {
      transform: translateY(-100%);
    }

    // TODO: make the language menu appear
  }

  &:not(.is-front-page):not(.visually-hidden) {
    & + * {
      @include media-breakpoint-up($mobile-breakpoint) {
        padding-top: calc(var(--header-height) - var(--app-outline-width));
      }
    }
  }

  & ~ .layout.footer {
    @include media-breakpoint-down($mobile-breakpoint) {
      margin-bottom: calc(var(--header-height) - var(--app-outline-width));
    }
  }

  .header {
    &-logo {
      height: calc(var(--header-height) / 1.5);
      margin: 0;
      font-size: 1em;

      @include media-breakpoint-up($mobile-breakpoint) {
        height: calc(var(--header-height) / 2);
      }

      .link {
        display: inline-block;
        height: calc(var(--header-height) / 1.5);
        text-decoration: none;

        @include media-breakpoint-up($mobile-breakpoint) {
          height: calc(var(--header-height) / 2);
        }
      }

      img {
        width: auto;
        height: calc(var(--header-height) / 2);
      }

      svg {
        width: calc(var(--header-height) / 1.5);
        height: calc(var(--header-height) / 1.5);
      }
    }

    &-nav {
      @include media-breakpoint-down($mobile-breakpoint) {
        font-size: 0.95em;
      }

      ul {
        padding: 0;
        margin: 0;
        list-style: none;
      }

      .nav {
        &-primary {
          display: flex;
          margin-bottom: -0.2125em;

          @include media-breakpoint-up($mobile-breakpoint) {
            margin-bottom: -0.25em;
          }

          > li {
            // display: inline-block;
            margin-right: calc(var(--app-outline-width) * 2);

            @include media-breakpoint-down($mobile-breakpoint) {
              &:last-child {
                margin-right: 0;
              }
            }
          }

          &.is-logged > li {
            &:not(.nav-user):not(:last-child) {
              margin-right: calc(var(--app-outline-width) * 1.5);

              @include media-breakpoint-up('xxs') {
                margin-right: calc(var(--app-outline-width) * 2);
              }
            }

            &:last-child {
              margin-right: calc(var(--app-outline-width) * 1);
            }

            &.nav-user {
              margin-right: 0;

              @include media-breakpoint-up($mobile-breakpoint) {
                margin-right: calc(var(--app-outline-width) * 2);
              }
            }

            > .link,
            > .button {
              letter-spacing: -0.075em;

              @include media-breakpoint-up('xxs') {
                letter-spacing: normal;
              }
            }
          }

          .link {
            font-weight: $font-weight-light;
          }

          .button {
            &.menu-button {
              font-weight: $font-weight-normal;
            }

            &.modal-button {
              font-weight: $font-weight-light;
            }
          }

          .link,
          .menu-button,
          .menu-button .button-label > span,
          .modal-button {
            display: inline-block;
            text-transform: uppercase;
            text-decoration: underline solid rgba(color('primary', false), 0);
            text-underline-offset: 0.075em;

            &:hover,
            &:focus,
            &:focus-visible,
            &:focus-within {
              outline: none;
              color: color('primary');
              text-decoration: underline solid rgba(color('primary', false), 0.3);
              text-underline-offset: 0.15em;
            }

            &.is-active {
              font-weight: $font-weight-normal;
            }
          }

          .menu-button .button-label {
            > span {
              transition: color 0.25s ease-out, text-decoration 0.25s ease-out, text-underline-offset 0.25s ease-out;
            }

            &:hover,
            &:focus,
            &:focus-visible,
            &:focus-within {
              > span {
                outline: none;
                color: color('primary');
                text-decoration: underline solid rgba(color('primary', false), 0.3);
                text-underline-offset: 0.15em;
              }
            }
          }

          .menu-button {
            .icon-chevron-down {
              display: inline-block;
              font-size: 0.75em;
              transform: translateY(0.25em);

              @include media-breakpoint-up('xxs') {
                margin-left: 0.25em;
              }
            }
          }

          .menu {
            ul {
              padding-top: 0.075em;

              > li:first-child {
                padding-right: 1.5em;
              }
            }

            .link {
              text-transform: none;
            }
          }
        }

        &-language {
          position: relative;

          > li {
            display: inline-block;

            &:not(:last-child) {
              &::after {
                content: '';
                display: inline-block;
                width: 5px;
                height: 1px;
                margin: 0 0.2em;
                background-color: color('primary');
              }
            }
          }

          .menu {
            &.l-dropdown {
              @include media-breakpoint-down($mobile-breakpoint) {
                top: calc(-0.2125em - var(--header-height) / 4 + 3px);
                transform: translateY(-100%);
              }

              @include media-breakpoint-up($mobile-breakpoint) {
                margin-top: calc(0.425em + 2px);
              }

              &.p-right {
                @include media-breakpoint-down($mobile-breakpoint) {
                  right: calc(var(--app-outline-width) - var(--scrollbar-width));
                }

                @include media-breakpoint-up($mobile-breakpoint) {
                  margin-right: calc(var(--app-outline-width) / -1 - var(--scrollbar-width));
                }
              }
            }

            .close-button {
              // @include visually-hidden;
            }
          }
        }

        &-user {
          @include media-breakpoint-down($mobile-breakpoint) {
            order: 4;
          }

          .menu {
            &.l-dropdown {
              &.p-bottom {
                @include media-breakpoint-down($mobile-breakpoint) {
                  right: 0;
                  transform: translateY(-100%);
                  top: 0;
                }
              }
            }

            &-button {
              .icon-user {
                font-size: 0.85em;

                @include media-breakpoint-up($mobile-breakpoint) {
                  display: none;
                }
              }

              .button-label {
                @include media-breakpoint-down($mobile-breakpoint) {
                  display: none;
                }

                @include media-breakpoint-up($mobile-breakpoint) {
                  display: inline-block;
                  vertical-align: top;
                  max-width: 8em;
                  overflow: hidden;
                  text-overflow: '...';
                  white-space: nowrap;
                }

                @include media-breakpoint-up('md') {
                  max-width: 30vw;
                }
              }

              .icon-chevron-down {
                @include media-breakpoint-down($mobile-breakpoint) {
                  display: none;
                }
              }
            }

            .form {
              margin-top: 1em;
              border-top: 1px solid rgba(color('primary', false), 0.2);

              &-status,
              &-error,
              &-sent {
                margin-left: 0;
                font-weight: $font-weight-light;
              }

              &-sent {
                color: color('green-light');
              }

              &-submit {
                > div {
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }
}
