.article {
  margin-bottom: calc(var(--app-outline-width) * 2);

  &:last-child {
    margin-bottom: 0;
  }

  &-header {
    margin-bottom: calc(var(--app-outline-width) * 1.5);

    &[class*='bg-'] {
      padding: var(--app-outline-width);
      border-radius: var(--app-outline-radius);
    }

    .article-title {
      margin: 0;
    }
  }

  &-title {
    @include font-uppercase;

    font-size: $h2-font-size;
  }

  &-content {
    font-size: 0.9048em;

    .article-title {
      margin-bottom: 0.25em;
      font-size: $h4-font-size;
    }
  }

  &-images {
    .image {
      border-radius: var(--app-outline-radius);
      overflow: hidden;

      img {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}
