.layout.footer {
  font-size: 1.2381em;
  text-transform: uppercase;

  @media (prefers-reduced-motion: no-preference) {
    transition: opacity 0.35s ease-out 0.35s;
  }

  body.is-loading & {
    opacity: 0;
  }

  /** Avoid flash of footer for browser history navigation */
  .layout.header + & {
    display: none;
  }

  .footer {
    &-section {
      &:first-child {
        position: relative;
        padding: calc(var(--app-outline-width) * 2);
        background-color: color('charcoal');
        border-radius: var(--app-outline-radius);
        font-size: 0.8571em;

        @include media-breakpoint-up('xl') {
          padding: calc(var(--app-outline-width) * 2) calc(var(--app-outline-width) * 3);
        }
      }

      &:last-child {
        padding: var(--app-outline-width) calc(var(--app-outline-width) * 2) 0 calc(var(--app-outline-width) * 2);
        font-size: 0.7143em;
        text-align: center;

        @include media-breakpoint-up('lg') {
          display: flex;
          justify-content: space-between;
          text-align: left;
        }

        @include media-breakpoint-up('xl') {
          padding: var(--app-outline-width) calc(var(--app-outline-width) * 3) 0 calc(var(--app-outline-width) * 3);
        }

        p {
          margin: 0;
        }

        .link {
          font-weight: $font-weight-light;
        }
      }
    }

    &-logo {
      font-size: 1.4286em;

      @include media-breakpoint-up('md') {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      img {
        width: auto;
        height: 1.5em;
      }
    }

    &-nav {
      @include media-breakpoint-up('sm') {
        column-count: 3;
      }

      @include media-breakpoint-up('md') {
        column-count: 2;
      }

      ul {
        padding: 0;
        margin: 0;
        list-style: none;
      }

      li {
        .link,
        .button {
          font-weight: $font-weight-medium;
          text-transform: inherit;
        }

        .button {
          text-decoration: underline solid rgba(color('white', false), 0.2);
          text-underline-offset: 0.15em;
          transition: color 0.25s ease-out, text-decoration 0.25s ease-out, text-underline-offset 0.25s ease-out;

          &:hover,
          &:focus,
          &:focus-visible,
          &:focus-within {
            text-decoration: underline solid rgba(color('white', false), 0);
            text-underline-offset: 0.075em;
          }
        }

        &:nth-child(n + 3) {
          .link,
          .button {
            font-weight: $font-weight-light;
          }
        }

        @include media-breakpoint-up('md') {
          &:nth-child(n + 4):nth-last-child(-n + 4) {
            text-align: right;
          }
        }
      }
    }
  }

  .beegreen {
    margin-left: 0.1em;
    height: 1.25em;
    vertical-align: middle;
    transform: translateY(-0.025em);
  }
}
